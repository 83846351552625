var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" In a previous quarter, students were not given thorough directions for how to make the sucrose standard solutions, so the quality of the plots varied significantly. Choose the comment for each plot that best reflects the quality of that plot at the prompt in the Group column. ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" a) After looking at the plots, decide on which calibration plot should be used. "),_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Group")]),_c('th',[_vm._v("Plot")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(" 1) "),_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('td',[_c('img',{staticStyle:{"max-width":"400px"},attrs:{"src":"/img/assignments/UCIrvine/plot1.png"}})])]),_c('tr',[_c('td',[_vm._v(" 2) "),_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('td',[_c('img',{staticStyle:{"max-width":"400px"},attrs:{"src":"/img/assignments/UCIrvine/plot2.png"}})])]),_c('tr',[_c('td',[_vm._v(" 3) "),_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('td',[_c('img',{staticStyle:{"max-width":"400px"},attrs:{"src":"/img/assignments/UCIrvine/plot3.png"}})])]),_c('tr',[_c('td',[_vm._v(" 4) "),_c('v-select',{staticStyle:{"max-width":"200px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('td',[_c('img',{staticStyle:{"max-width":"400px"},attrs:{"src":"/img/assignments/UCIrvine/plot4.png"}})])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }