<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In a previous quarter, students were not given thorough directions for how to make the
        sucrose standard solutions, so the quality of the plots varied significantly. Choose the
        comment for each plot that best reflects the quality of that plot at the prompt in the Group
        column.
      </p>
      <p class="mb-2">
        a) After looking at the plots, decide on which calibration plot should be used.
        <v-select
          v-model="inputs.input1"
          style="max-width: 200px"
          :items="items1"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <table>
        <thead>
          <tr>
            <th>Group</th>
            <th>Plot</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              1)
              <v-select
                v-model="inputs.input2"
                style="max-width: 200px"
                :items="items2"
                item-text="text"
                item-value="value"
                label="Select option"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <img style="max-width: 400px" src="/img/assignments/UCIrvine/plot1.png" />
            </td>
          </tr>

          <tr>
            <td>
              2)
              <v-select
                v-model="inputs.input3"
                style="max-width: 200px"
                :items="items3"
                item-text="text"
                item-value="value"
                label="Select option"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <img style="max-width: 400px" src="/img/assignments/UCIrvine/plot2.png" />
            </td>
          </tr>

          <tr>
            <td>
              3)
              <v-select
                v-model="inputs.input4"
                style="max-width: 200px"
                :items="items3"
                item-text="text"
                item-value="value"
                label="Select option"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <img style="max-width: 400px" src="/img/assignments/UCIrvine/plot3.png" />
            </td>
          </tr>

          <tr>
            <td>
              4)
              <v-select
                v-model="inputs.input5"
                style="max-width: 200px"
                :items="items3"
                item-text="text"
                item-value="value"
                label="Select option"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <img style="max-width: 400px" src="/img/assignments/UCIrvine/plot4.png" />
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S1Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      items1: [
        {text: '1', value: 'one'},
        {text: '2', value: 'two'},
        {text: '3', value: 'three'},
        {text: '4', value: 'four'},
      ],
      items2: [
        {text: 'Poor spread of data points & good $\\text{R}^2$ value', value: 'poorSpreadGoodR'},
        {
          text: 'Good spread of data points & decent $\\text{R}^2$ value',
          value: 'goodSpreadDecentR',
        },
        {text: 'Poor spread of data points & poor $\\text{R}^2$ value', value: 'poorSpreadPoorR'},
      ],
      items3: [
        {
          text: 'Good spread of data points & decent $\\text{R}^2$ value',
          value: 'goodSpreadDecentR',
        },
        {
          text: 'Poor spread of data points & good $\\text{R}^2$ value',
          value: 'poorSpreadGoodR',
        },
        {
          text: 'Data points only cover a portion of range & good $\\text{R}^2$ value',
          value: 'portionRangeGoodR',
        },
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
